/**
 * List of supported key events
 *
 * @example
 * esc pressed, navigation keys pressed, shortcut command pressed
 */
export const enum KeyboardKeyEvent {
  /**
   * Pressed esc key
   */
  esc = 'esc',

  /**
   * Arrow left pressed
   */
  arrowLeft = 'arrowLeft',

  /**
   * Arrow right pressed
   */
  arrowRight = 'arrowRight',
}
