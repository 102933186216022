import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { configGetCountry } from 'common/helper/config/get-country';
import { FilterStoreFactory } from 'common/module/filter/store.factory';
import { FilterService } from 'common/service/filter/service';

import { SearchBarComponentFactory } from './component-factory';

export const SearchBarComponent = SearchBarComponentFactory({
  filterStore: FilterStoreFactory(),
  filterService: FilterService(),
  countryCode: configGetCountry().code,
  windowService: WindowService(),
});
