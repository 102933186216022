import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { StatsContexterServiceInterface } from 'pf-frontend-common/src/service/stats-contexter/service.interface';

import { PreactMarkupComponentsInterface } from 'common/helper/preact/markup/components.interface';
import { FilterStore } from 'common/module/filter/store';
import { StatsServiceInterface } from 'common/service/stats/service.interface';
import { preactMarkup } from 'common/typings/preact-markup';
import { ErrorPageViewOptionsInterface } from 'desktop/module/error-page/view-options.interface';

export class ErrorPageView implements ViewBasicInterface {
  /**
   * Error code
   */
  private statusCode: number;

  /**
   * View HTML element
   */
  private element: HTMLElement;

  /**
   * Constructor
   */
  constructor(
    private statsContexterService: StatsContexterServiceInterface,
    private statsService: StatsServiceInterface,
    private preactMarkupHelper: preactMarkup,
    private components: PreactMarkupComponentsInterface,
    private filterStoreService: FilterStore
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: ErrorPageViewOptionsInterface): void {
    this.statusCode = options.statusCode;
    this.element = options.element;

    this.filterStoreService.initialize();

    this.statsContexterService.setErrorPage(true);

    this.preactMarkupHelper(this.element, this.components);
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    // Log stats
    this.stats();
  }

  /**
   * Log stats for the page
   */
  private stats(): void {
    // Log stats
    this.statsService.pageView();
    this.statsService.errorPage({
      http_status_code: this.statusCode,
    });
  }
}
