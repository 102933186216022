import { h } from 'preact';

import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';

import { filterWidgetRenderMap } from './render-map';
import { FilterWidgetTypeEnum } from './type.enum';

export const FilterWidgetMoreFiltersComponent = (props: {
  filterType: FilterWidgetTypeEnum;
  className?: string;
  onChangeFilters: (value: FilterParametersBaseInterface) => void;
  filterParams: FilterParametersBaseInterface;
}) => {
  const { filterType, ...componentProps } = props;
  const { MoreFiltersComponent } = filterWidgetRenderMap[filterType];

  return <MoreFiltersComponent {...componentProps} />;
};
