import { PropertySearchStore } from 'common/data/property-search/store';
import { FilterAdapter } from 'common/module/filter/adapter';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { PropertySearchFormSearchAdapter } from 'common/module/property-search-form/search/adapter';

export class FilterSearchStore {
  /**
   * Constructor
   */
  constructor(private propertySearchStore: PropertySearchStore) {}

  /**
   * Get the total counts of the searched properties
   */
  public getPropertiesCount(parameters: FilterParametersInterface): Promise<number> {
    return this.propertySearchStore
      .search(
        new PropertySearchFormSearchAdapter().toPropertySearchStoreSearchInput(
          FilterAdapter.fromParamsToValue(parameters),
          {
            include: [
              'properties',
              'properties.property_type',
              'properties.property_images',
              'properties.location_tree',
              'properties.agent',
              'properties.agent.languages',
              'properties.broker',
              'properties.project',
              'properties.project.developer',
              'properties.project.images',
              'properties.project_property',
              'properties.project_property.unit',
              'properties.project_property.unit.floor_plans',
              'smart_ads',
              'smart_ads.agent',
              'smart_ads.broker',
              'smart_ads.property_type',
              'smart_ads.property_images',
              'smart_ads.location_tree',
              'direct_from_developer',
              'direct_from_developer.property_type',
              'direct_from_developer.property_images',
              'direct_from_developer.location_tree',
              'direct_from_developer.agent',
              'direct_from_developer.broker',
              'cts',
              'cts.agent',
              'cts.broker',
              'cts.property_type',
              'cts.property_images',
              'cts.location_tree',
              'similar_properties',
              'similar_properties.agent',
              'similar_properties.broker',
              'similar_properties.property_type',
              'similar_properties.property_images',
              'similar_properties.location_tree',
              'agent_smart_ads',
              'agent_smart_ads.broker',
              'agent_smart_ads.languages',
              'agent_properties_smart_ads',
              'agent_properties_smart_ads.agent',
              'agent_properties_smart_ads.broker',
              'agent_properties_smart_ads.location_tree',
              'agent_properties_smart_ads.property_type',
              'agent_properties_smart_ads.property_images',
            ].join(','),
          }
        )
      )
      .then((response) => response.properties.pagination.totalCount);
  }
}
