import { h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { ButtonComponent } from 'common/module/button/component';
import { ButtonComponentTypeEnum } from 'common/module/button/component-type.enum';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterSingleSelectionTemplate } from 'common/module/filter-single-selection/template';
import { DropdownTemplate } from 'library/dropdown/template';
import { DropdownVariantEnum } from 'library/dropdown/variant.enum';

import { FilterWidgetComponent } from '../filter-widget/component';
import { KeepSameWidthWhenOpened } from '../keep-same-width-when-opened/component';
import { MultiLocationSelectorComponent } from '../multi-location-selector/component';
import { SearchBarTemplatePropsInterface } from './template-props.interface';

export const SearchBarTemplate = (props: SearchBarTemplatePropsInterface) => (
  <section className='search-bar__section'>
    <div className='search-bar__location'>
      <MultiLocationSelectorComponent
        onChange={(locationsIds: string[]) =>
          props.onChangeFilters({ [FilterParamsEnum.locationsIds]: { value: locationsIds } })
        }
        value={props.filterParams[FilterParamsEnum.locationsIds].value}
      />
    </div>
    <ButtonComponent
      cssClass='search-bar-find-button'
      componentType={ButtonComponentTypeEnum.type2}
      onClick={props.onSubmit}
    >
      {i18nTranslate('Find')}
    </ButtonComponent>
    <div className='search-bar__secondary-filters'>
      <KeepSameWidthWhenOpened
        Component={FilterSingleSelectionTemplate}
        componentProps={{
          ...props.filterParams[FilterParamsEnum.categoryId],
          dropdownSize: props.secondaryFiltersSize,
          onChange: ({ value }) => {
            if (props.filterParams[FilterParamsEnum.categoryId].value !== value) {
              props.onChangeCategory(value);
            }
          },
        }}
      />
      {props.visibleFilterTypes.map((filterType) => (
        <FilterWidgetComponent
          key={filterType}
          filterType={filterType}
          onChangeFilters={props.onChangeFilters}
          filterParams={props.filterParams}
          dropdownSize={props.secondaryFiltersSize}
        />
      ))}
      <div className='more-filters-selector__wrapper'>
        <DropdownTemplate
          isLabelVisible={!props.activeHiddenFiltersCount}
          size={props.secondaryFiltersSize}
          variant={DropdownVariantEnum.primary}
          label={i18nTranslate('More Filters')}
          text={
            props.activeHiddenFiltersCount
              ? i18nTranslate('More Filters ({count})').replace('{count}', String(props.activeHiddenFiltersCount))
              : ''
          }
          onClick={props.onMoreFiltersClick}
        />
      </div>
    </div>
  </section>
);
