import { cloneElement, FunctionalComponent, h, toChildArray } from 'preact';

import { Modal2ComponentPropsInterface } from 'common/module/modal2/component-props.interface';
import { Modal2Template } from 'common/module/modal2/template';
import { Modal2TemplatePropsInterface } from 'common/module/modal2/template-props.interface';
import { Modal2ViewStore } from 'common/module/modal2/view-store';
import { Modal2ViewStoreFactory } from 'common/module/modal2/view-store.factory';
import { Modal2ViewStoreStateInterface } from 'common/module/modal2/view-store-state.interface';
import { preactConnectStore } from 'common/module/preact/connect-store';
import { preactLifecycleComponent } from 'common/module/preact/lifecycle-component';
import { PreactLifecycleComponentPropsInterface } from 'common/module/preact/lifecycle-component-props.interface';
import { PreactPortalComponent } from 'common/module/preact/portal/component';

const ModalPortal: FunctionalComponent<Modal2ViewStoreStateInterface & Modal2ComponentPropsInterface> = (props) => {
  const Template = props.template || Modal2Template;
  return (
    props.isOpened && (
      <PreactPortalComponent into='body'>
        <Template {...props} />
      </PreactPortalComponent>
    )
  );
};

const Modal: FunctionalComponent<Modal2ViewStoreStateInterface & Modal2ComponentPropsInterface> = (props) => {
  if (props.triggerElement) {
    return (cloneElement as any)(
      props.triggerElement,
      {
        ...props.triggerElement.props,
        onClick: props.onClickOpen,
      },
      [<ModalPortal {...props} />, ...toChildArray(props.triggerElement.props.children)]
    );
  }

  return <ModalPortal {...props} />;
};

export const Modal2Component = preactConnectStore<
  Modal2ViewStore,
  Modal2TemplatePropsInterface & PreactLifecycleComponentPropsInterface,
  Modal2ComponentPropsInterface
>(
  Modal2ViewStoreFactory,
  (store) => ({
    ...store.getState(),
    onComponentWillReceiveProps: (
      nextProps: Modal2ComponentPropsInterface & PreactLifecycleComponentPropsInterface
    ) => {
      store.setIsOpened(nextProps.isOpened);
      if (store.getState().onClose !== nextProps.onClose) {
        store.setOnClose(nextProps.onClose);
      }
      if (store.getState().onOpen !== nextProps.onOpen) {
        store.setOnOpen(nextProps.onOpen);
      }
    },
  }),
  (store, { props }) =>
    store.initialize({
      isFullScreen: !!props.isFullScreen,
      isOpened: props.isOpened,
      onClose: props.onClose,
      onOpen: props.onOpen,
    })
)(preactLifecycleComponent(Modal));
