import { EventEmitterInterface } from 'pf-frontend-common/dist/module/event/emitter.interface';
import { WindowServiceInterface } from 'pf-frontend-common/dist/service/window/service.interface';
import { BrowserWindowEvent } from 'pf-frontend-common/src/module/browser/window.event';

import { KeyboardCharcodeEnum } from 'common/module/keyboard/charcode.enum';
import { KeyboardKeyEvent } from 'common/module/keyboard/key.event';
import { KeyboardServiceInterface } from 'common/module/keyboard/service.interface';

export class KeyboardStore implements KeyboardServiceInterface {
  /**
   * Constructor
   */
  constructor(private evenEmitter: EventEmitterInterface, private windowService: WindowServiceInterface) {}

  /**
   * Init store
   */
  public initialize(): void {
    this.windowService.getEventEmitter().addListener(BrowserWindowEvent.keyup, this.onKeyupWindow);
  }

  /**
   * @inheritdoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.evenEmitter;
  }

  /**
   * Fired document keyup event
   */
  private onKeyupWindow = (e: KeyboardEvent) => {
    if (e.keyCode === KeyboardCharcodeEnum.escape) {
      this.getEventEmitter().emit(KeyboardKeyEvent.esc);

      return;
    }

    if (e.keyCode === KeyboardCharcodeEnum.left) {
      this.getEventEmitter().emit(KeyboardKeyEvent.arrowLeft);

      return;
    }

    if (e.keyCode === KeyboardCharcodeEnum.right) {
      this.getEventEmitter().emit(KeyboardKeyEvent.arrowRight);

      return;
    }
  };
}
