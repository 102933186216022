import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { useFilterStoreParams } from 'common/module/filter/store/params';
import { useFilterStoreSyncFromService } from 'common/module/filter/store/sync-from-service.hook';
import { filterWidgetGetActiveHiddenFiltersCount } from 'desktop/module/filter-widget/get-active-hidden-filters-count';
import { DropdownSizeEnum } from 'library/dropdown/size.enum';

import { filterWidgetComputeVisibleAndHiddenFilters } from '../filter-widget/compute-visible-and-hidden-filters';
import { FilterWidgetResetValues } from '../filter-widget/reset-values';
import { SearchBarComponentFactoryPropsInterface } from './component-factory-props.interface';
import { SearchBarComponentPropsInterface } from './component-props.interface';
import { SearchBarMoreFiltersComponent } from './more-filters/component';
import { SearchBarTemplate } from './template';

function getDropdownSizeBasedOnWindowWidth(width: number): DropdownSizeEnum {
  return width > 1149 ? DropdownSizeEnum.normal : DropdownSizeEnum.small;
}

export const SearchBarComponentFactory =
  ({ filterStore, filterService, countryCode, windowService }: SearchBarComponentFactoryPropsInterface) =>
  (props: SearchBarComponentPropsInterface) => {
    const [onChangeFilters] = useState(() => filterStore.update.bind(filterStore));
    const [defaultValues] = useState(() => getFilterDefaultParams());
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [secondaryFiltersSize, setSecondaryFiltersSize] = useState(
      getDropdownSizeBasedOnWindowWidth(windowService.getInnerWidth())
    );

    useEffect(() => {
      const windowObject = windowService.getNative();
      const listener = () => {
        setSecondaryFiltersSize(getDropdownSizeBasedOnWindowWidth(windowService.getInnerWidth()));
      };
      windowObject?.addEventListener('resize', listener);
      return () => {
        windowObject?.removeEventListener('resize', listener);
      };
    }, [secondaryFiltersSize]);

    // syncronyze local store with service any time service is got changed
    useFilterStoreSyncFromService({ filterStore, filterService });

    const filterParams = useFilterStoreParams(filterStore);

    const { visibleFilterTypes, hiddenFiltersTypes } = filterWidgetComputeVisibleAndHiddenFilters({
      filterParams,
      countryCode,
    });

    return (
      <Fragment>
        <SearchBarTemplate
          secondaryFiltersSize={secondaryFiltersSize}
          activeHiddenFiltersCount={filterWidgetGetActiveHiddenFiltersCount(
            filterParams,
            hiddenFiltersTypes,
            defaultValues
          )}
          onMoreFiltersClick={() => setShowMoreFilters(true)}
          visibleFilterTypes={visibleFilterTypes}
          filterParams={filterParams}
          onChangeFilters={onChangeFilters}
          onChangeCategory={FilterWidgetResetValues(onChangeFilters)}
          onSubmit={() => props.onSubmit(filterParams)}
        />
        {showMoreFilters && (
          <SearchBarMoreFiltersComponent
            onClose={() => setShowMoreFilters(false)}
            initialFilterParams={filterParams}
            includedFilterTypes={hiddenFiltersTypes}
            onSubmit={(newFilterParams) => {
              setShowMoreFilters(false);
              props.onSubmit(newFilterParams);
            }}
          />
        )}
      </Fragment>
    );
  };
