import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { StatsContextPropertySearchInterface } from 'pf-frontend-common/src/module/stats/context/property-search.interface';
import { CtaSortPropertyType } from 'pf-frontend-common/src/module/stats/types';

import { PropertySearchStoreSearchFilterInterface } from 'common/data/property-search/store/search/filter.interface';
import { PropertySearchStoreSearchInputInterface } from 'common/data/property-search/store/search/input.interface';
import { objectFilterNonValue } from 'common/helper/object/filter/non-value';

import { PropertySearchFormStoreStateParamsInterface } from '../store/state-params.interface';

// TODO-FE[later]: Refactor into transformers
export class PropertySearchFormSearchAdapter {
  /**
   * Transforms PropertySerpStoreStateParamsInterface into PropertySearchStoreSearchInputInterface
   */
  public toPropertySearchStoreSearchInput(
    input: PropertySearchFormStoreStateParamsInterface,
    context: DataKeyValueStringInterface
  ): PropertySearchStoreSearchInputInterface {
    const filter: PropertySearchStoreSearchFilterInterface = {
      category_id: input['filter[categoryId]'],
      property_type_id: input['filter[propertyTypeId]'],
      locations_ids: input['filter[locations_ids]'] && input['filter[locations_ids]'].map((loc) => parseInt(loc, 10)),
      min_price: input['filter[min_price]'],
      max_price: input['filter[max_price]'],
      min_area: input['filter[min_area]'],
      max_area: input['filter[max_area]'],
      keywords: input['filter[keyword]'],
      furnished: input['filter[furnishing]'],
      amenities: input['filter[amenities]'],
      number_of_bedrooms: input['filter[number_of_bedrooms]'],
      number_of_bathrooms: input['filter[number_of_bathrooms]'],
      price_type: input['filter[price_period]'],
      completion_status: input['filter[completion_status]'],
      exclude_locations_descendants: input['filter[exclude_locations_descendants]'],
      exact_location: input['filter[exact_location]'],
      bounding_box: input['filter[bounding_box]'],
      geohash_viewport: input['filter[geohash_viewport]'],
      hash_box: input['filter[hash_box]'],
      payment_method: input['filter[payment_method]'],
      utilities_price_type: input['filter[utilities_price_type]'],
      virtual_viewings: input['filter[virtual_viewings]'],
      is_developer_property: input['filter[is_developer_property]'],
      min_installment_years: input['filter[min_installment_years]'],
      max_installment_years: input['filter[max_installment_years]'],
      verified: input['filter[verified]'],
      trusted_agent: input['filter[trusted_agent]'],
    };

    return objectFilterNonValue({
      page: objectFilterNonValue({
        number: input['page[number]'],
        limit: input['page[limit]'],
      }),
      sort: input.sort,
      query: input.query,
      filter: objectFilterNonValue(filter),
      ...context,
    });
  }

  /**
   * Transforms PropertySearchFormState into StatsContextPropertySearch
   */
  public toPropertySearchFormStats(
    input: PropertySearchFormStoreStateParamsInterface
  ): StatsContextPropertySearchInterface {
    return objectFilterNonValue({
      category: input['filter[categoryId]'],
      locations:
        input['filter[locations_ids]'] && input['filter[locations_ids]'].map((location) => parseInt(location, 10)),
      property_type_id: input['filter[propertyTypeId]'],
      rental_period: input['filter[price_period]'],
      price_min: input['filter[min_price]'],
      price_max: input['filter[max_price]'],
      area_min: input['filter[min_area]'],
      area_max: input['filter[max_area]'],
      furnished: input['filter[furnishing]'],
      amenities: input['filter[amenities]'],
      number_of_bedrooms: input['filter[number_of_bedrooms]'],
      number_of_bathrooms: input['filter[number_of_bathrooms]'],
      keywords: input['filter[keyword]'],
      payment_method: input['filter[payment_method]'],
      utilities_price_type: input['filter[utilities_price_type]'],
      virtual_viewings: input['filter[virtual_viewings]'],
      is_developer_property: input['filter[is_developer_property]'],
      min_installment_years: input['filter[min_installment_years]'],
      max_installment_years: input['filter[max_installment_years]'],
      verified: input['filter[verified]'],
      completion_status: input['filter[completion_status]'],
      sort: input.sort as CtaSortPropertyType,
    });
  }
}
