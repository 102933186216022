import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';

import { PropertySearchStoreOptionsInterface } from 'common/data/property-search/store-options.interface';
import { jsonApiFlatParams } from 'common/helper/json-api/flat-params';
import { jsonApiModelClone } from 'common/helper/json-api/model-clone';
import { objectCompare } from 'common/helper/object/compare';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { CookieAuthenticatorApiServiceInterface } from 'common/module/cookie-authenticator/api-service.interface';
import { DataStorePaginationAdapter } from 'common/module/data/store/pagination-adapter';
import { JsonApiMetaPaginationInterface } from 'common/module/json-api/meta/pagination.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { PropertySearchStoreServiceInterface } from 'common/service/property-search-store/service.interface';
import { PropertySerpBundleRawLtpDataInterface } from 'desktop/entry/property-serp/bundle/raw-ltp-data.interface';
import { PropertySerpLtpDataInterface } from 'desktop/module/property/serp/ltp-data.interface';

import { PropertySearchModel } from './model';
import { PropertySearchStoreEvent } from './store.event';
import { PropertySearchStoreSearchApiResponseInterface } from './store/search/api-response.interface';
import { PropertySearchStoreSearchInputInterface } from './store/search/input.interface';
import { PropertySearchStoreSearchOutputInterface } from './store/search/output.interface';
import { PropertySearchStoreCacheInterface } from './store-cache.interface';

export class PropertySearchStore implements PropertySearchStoreServiceInterface {
  private static InitialCacheRequest: PropertySearchStoreCacheInterface = {
    filters: null,
    request: { abort: () => false },
  };

  /**
   * Latest request
   */
  private cacheRequest: PropertySearchStoreCacheInterface = PropertySearchStore.InitialCacheRequest;

  /**
   * Unique Id sequence
   */
  private id: number = 0;

  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    search: {
      path: this.apiEndpointService.getPath('/search'),
      method: 'GET',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private apiService: CookieAuthenticatorApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private jsonApiStore: JsonApiStore<PropertySearchModel>
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public initialize(options: PropertySearchStoreOptionsInterface): void {
    this.jsonApiStore.reset();
    if (options.model) {
      this.jsonApiStore.add(jsonApiModelClone<PropertySearchModel>(options.model));

      const model = this.jsonApiStore.find('0');

      this.getEventEmitter().emit(PropertySearchStoreEvent.initialize, <PropertySearchStoreSearchOutputInterface>{
        properties: {
          list: model.properties,
          // TODO-FE[later]: Can we parse metadata associated with a relationship (not primary resource)?
          pagination: null,
        },
        smartAds: {
          list: model.smart_ads,
          // TODO-FE[later]: Can we parse metadata associated with a relationship (not primary resource)?
          pagination: null,
        },
        dfd: {
          list: model.direct_from_developer,
          // TODO-FE[later]: Can we parse metadata associated with a relationship (not primary resource)?
          pagination: null,
        },
        cts: {
          list: model.cts,
          // TODO-FE[later]: Can we parse metadata associated with a relationship (not primary resource)?
          pagination: null,
        },
        similarProperties: {
          list: model.similar_properties,
          // TODO-FE[later]: Can we parse metadata associated with a relationship (not primary resource)?
          pagination: null,
        },
        agentSmartAds: {
          list: model.agent_smart_ads,
        },
        ads: {
          targeting: model.meta.ad_targeting,
        },
        areaSpecialistWithProperty: {
          list: model.agent_properties_smart_ads,
        },
        title: model.meta.h1_title,
        documentTitle: model.meta.meta_title,
        similarPropertiesTitle: model.meta.similar_properties_title,
        breadcrumbTitle: model.meta && model.meta.breadcrumb_title,
        breadcrumbs: model.meta && model.meta.breadcrumbs,
        newPropertyCount: model.meta.new_property_count,
      });
    }

    // From JsonApi payload
    if (options.payload) {
      this.jsonApiStore.syncWithMeta(options.payload);
      this.getEventEmitter().emit(PropertySearchStoreEvent.initialize, this.getSearchOutput(options.payload));
    }
  }

  /**
   * @inheritDoc
   */
  public getModel(): PropertySearchModel {
    return this.jsonApiStore.find('0');
  }

  /**
   * @inheritDoc
   */
  public getSearchOutput(
    payload: PropertySearchStoreSearchApiResponseInterface
  ): PropertySearchStoreSearchOutputInterface {
    this.jsonApiStore.reset();
    const sync = this.jsonApiStore.sync(payload);

    if (Array.isArray(sync)) {
      return null;
    }

    const output = {
      properties: {
        list: sync.properties,
        pagination:
          payload.data.relationships &&
          payload.data.relationships.properties &&
          new DataStorePaginationAdapter(
            <JsonApiMetaPaginationInterface>payload.data.relationships.properties.meta
          ).getData(),
      },
      smartAds: {
        list: sync.smart_ads,
        pagination:
          payload.data.relationships &&
          payload.data.relationships.smart_ads &&
          new DataStorePaginationAdapter(
            <JsonApiMetaPaginationInterface>payload.data.relationships.smart_ads.meta
          ).getData(),
      },
      dfd: {
        list: sync.direct_from_developer,
        pagination:
          payload.data.relationships &&
          payload.data.relationships.direct_from_developer &&
          new DataStorePaginationAdapter(
            <JsonApiMetaPaginationInterface>payload.data.relationships.direct_from_developer.meta
          ).getData(),
      },
      cts: {
        list: sync.cts,
        pagination:
          payload.data.relationships &&
          payload.data.relationships.cts &&
          new DataStorePaginationAdapter(<JsonApiMetaPaginationInterface>payload.data.relationships.cts.meta).getData(),
      },
      similarProperties: {
        list: sync.similar_properties,
        pagination:
          payload.data.relationships &&
          payload.data.relationships.similar_properties &&
          new DataStorePaginationAdapter(
            <JsonApiMetaPaginationInterface>payload.data.relationships.similar_properties.meta
          ).getData(),
      },
      agentSmartAds: {
        list: sync.agent_smart_ads,
      },
      ads: {
        targeting: sync.meta.ad_targeting,
      },
      areaSpecialistWithProperty: {
        list: sync.agent_properties_smart_ads,
      },
      hashBox: sync.meta.hash_box,
      breadcrumbTitle: sync.meta && sync.meta.breadcrumb_title,
      breadcrumbs: sync.meta && sync.meta.breadcrumbs,
      title: sync.meta && sync.meta.h1_title,
      documentTitle: sync.meta && sync.meta.meta_title,
      similarPropertiesTitle: sync.meta && sync.meta.similar_properties_title,
      locationsMeta: sync.meta.locations,
      locations: sync.locations,
      links: sync.links,
      newPropertyCount: sync.meta.new_property_count,
    };

    return output;
  }

  public getLtpData(
    ltpData?: PropertySerpBundleRawLtpDataInterface['data']['attributes']
  ): PropertySerpLtpDataInterface | null {
    const { current, default: defaultLanguage } = window.propertyfinder.language;
    const languageIsDefault = defaultLanguage === current;
    return ltpData
      ? {
          headingTitle: languageIsDefault ? ltpData.h1_title_primary : ltpData.h1_title_secondary,
          title: languageIsDefault ? ltpData.title_primary : ltpData.title_secondary,
        }
      : null;
  }

  public search(input: PropertySearchStoreSearchInputInterface): Promise<PropertySearchStoreSearchOutputInterface> {
    const localId = ++this.id;

    if (objectCompare(input, this.cacheRequest.filters)) {
      return Promise.reject(null);
    }

    this.cacheRequest.request.abort();

    // Emit event: start
    this.getEventEmitter().emit(PropertySearchStoreEvent.searchStart);

    return new Promise((resolve, reject) => {
      const request = this.apiService.request(
        this.endpoint.search.method,
        this.endpoint.search.path,
        objectFilterNonOrEmptyValue(jsonApiFlatParams(input)),
        false
      );

      request
        .then((response: HttpApiResponseInterface) => {
          if (localId !== this.id) {
            return;
          }

          this.cacheRequest = {
            filters: input,
            request,
          };

          const output = this.getSearchOutput(response.data);

          // Success
          this.getEventEmitter().emit(PropertySearchStoreEvent.searchSuccess, output);
          resolve(output);

          this.cacheRequest = PropertySearchStore.InitialCacheRequest;
        })
        .catch((response: { status: number }) => {
          // Failure
          if (this.id === localId) {
            this.getEventEmitter().emit(PropertySearchStoreEvent.searchFailure, response);
            reject(response);
          }
        });
    });
  }
}
