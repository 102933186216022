import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';

import { configGetSettings } from 'common/helper/config/get-settings';
import { AbTestService } from 'common/service/ab-test/service';
import { AutocompleteCacheLocationsDelayedService } from 'common/service/autocomplete-cache-locations/delayed/service';
import { HeaderService } from 'common/service/header/service';
import { LanguageSelectorService } from 'common/service/language-selector/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';
import { ErrorPageBundleSettingsInterface } from 'desktop/entry/error-page/bundle/settings.interface';
import { ErrorPageViewFactory } from 'desktop/module/error-page/view.factory';
import { UserAvatarComponent } from 'desktop/module/user/avatar/component';

AbTestService().initialize();

const bundleSettings = configGetSettings<ErrorPageBundleSettingsInterface>();

// Common services
HeaderService()
  .setComponents({
    UserAvatarComponent,
  })
  .render();
UserAuthenticationService();
LanguageSelectorService();

AutocompleteCacheLocationsDelayedService();

const element = domQuerySelector(document, '[data-qs="error-page"]');

const errorPageView = ErrorPageViewFactory();

errorPageView.initialize({
  element,
  statusCode: bundleSettings.error.code,
});

errorPageView.render();
