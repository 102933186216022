import { useEffect, useState } from 'preact/hooks';

import { FilterSearchStore } from 'common/module/filter/search/store';

import { FilterStore } from '../store';
import { useFilterStoreParams } from './params';

export const useResultCount = (filterSearchStore: FilterSearchStore, filterStore: FilterStore) => {
  const [resultCount, setResultCount] = useState(-1);
  const filterStoreParams = useFilterStoreParams(filterStore);

  useEffect(() => {
    setResultCount(-1);
    filterSearchStore.getPropertiesCount(filterStoreParams).then(setResultCount);
  }, [filterStoreParams]);

  return resultCount;
};
