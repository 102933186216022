import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';
import { JsonApiModelInterface } from 'pf-frontend-common/src/module/json-api/model.interface';

/**
 * Clone the @param model instance and return a new model with the same data
 */
export function jsonApiModelClone<Model extends JsonApiModelInterface>(model: Model): Model {
  const newModel = <Model>new JsonApiModel();

  newModel.jsonApiType = model.jsonApiType;
  newModel.id = model.id;

  model.jsonApiAttributes.forEach((attr) => {
    newModel.setAttribute(attr, (<any>model)[attr]);
  });

  model.jsonApiRelationships.forEach((attr) => {
    newModel.setRelationship(attr, (<any>model)[attr]);
  });

  model.jsonApiLinks.forEach((key) => {
    newModel.links[key] = model.links[key];
    model.jsonApiLinks.push(key);
  });

  model.jsonApiMeta.forEach((key) => {
    newModel.meta[key] = model.meta[key];
    newModel.jsonApiMeta.push(key);
  });

  return newModel;
}
