import { domQuerySelector } from '@propertyfinder/pf-frontend-common/src/helper/dom/query-selector';
import { EventEmitterInterface } from 'pf-frontend-common/dist/module/event/emitter.interface';
import { BrowserDocumentServiceInterface } from 'pf-frontend-common/dist/service/browser-document/service.interface';
import { WindowServiceInterface } from 'pf-frontend-common/dist/service/window/service.interface';

import { DataStore } from 'common/module/data/store';
import { KeyboardKeyEvent } from 'common/module/keyboard/key.event';
import { KeyboardServiceInterface } from 'common/module/keyboard/service.interface';
import { Modal2CssClassEnum } from 'common/module/modal2/css-class.enum';
import { Modal2CssSelectorEnum } from 'common/module/modal2/css-selector.enum';
import { Modal2EventEnum } from 'common/module/modal2/event.enum';
import { Modal2ViewStoreStateInterface } from 'common/module/modal2/view-store-state.interface';

export class Modal2ViewStore extends DataStore<Modal2ViewStoreStateInterface> {
  /**
   * Window scroll top position
   */
  private scrollTop: number;

  /**
   * Content HTML element
   */
  private contentElement: HTMLElement;

  /**
   * HTML element
   */
  private htmlElement: {
    header: HTMLElement | null;
    main: HTMLElement | null;
    footer: HTMLElement | null;
  };

  /**
   * Constructor
   */
  public constructor(
    eventEmitter: EventEmitterInterface,
    private windowService: WindowServiceInterface,
    private browserDocumentService: BrowserDocumentServiceInterface,
    private keyboardService: KeyboardServiceInterface,
    private documentService: BrowserDocumentServiceInterface,
    private domQuerySelectorHelper: typeof domQuerySelector,
    private modal2InteractionService: EventEmitterInterface
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritDoc
   */
  public initialize(
    options: Pick<Modal2ViewStoreStateInterface, 'onOpen' | 'onClose' | 'isOpened' | 'isFullScreen'>
  ): void {
    this.state = {
      isFullScreen: !!options.isFullScreen,
      isOpened: !!options.isOpened,
      onClick: this.onClick,
      onClose: options.onClose,
      onOpen: options.onOpen,
      onClickOpen: this.onClickOpen,
      onClickClose: this.onClickClose,
      onContentRef: this.onContentRef,
      onComponentWillUnmount: this.onComponentWillUnmount,
      setOnClose: this.setOnClose,
    };

    this.keyboardService.getEventEmitter().addListener(KeyboardKeyEvent.esc, this.onEsc);

    if (this.state.isFullScreen) {
      this.htmlElement = {
        header: this.domQuerySelectorHelper(this.documentService.getDocument(), Modal2CssSelectorEnum.header),
        main: this.domQuerySelectorHelper(this.documentService.getDocument(), Modal2CssSelectorEnum.main),
        footer: this.domQuerySelectorHelper(this.documentService.getDocument(), Modal2CssSelectorEnum.footer),
      };
    }
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    this.keyboardService.getEventEmitter().removeListener(KeyboardKeyEvent.esc, this.onEsc);
  }

  /**
   * @inheritdoc
   */
  public getState(): Modal2ViewStoreStateInterface {
    return this.state;
  }

  /**
   * Set is opened state property
   */
  public setIsOpened(isOpened: boolean): void {
    if (!!this.state.isOpened === !!isOpened) {
      return;
    }

    this.state.isOpened = isOpened;
    this.setState(this.state);

    if (isOpened) {
      this.open();
      return;
    }

    this.close();
  }

  /**
   * Set on close event handler
   */
  public setOnClose(onClose: () => void): void {
    this.state.onClose = onClose;
  }

  /**
   * Set on open event handler
   */
  public setOnOpen(onOpen: () => void): void {
    this.state.onOpen = onOpen;
  }

  /**
   * @inheritdoc
   */
  private onComponentWillUnmount = (): void => {
    this.toggleBodyInteractivity(true);
  };

  /**
   * Open modal
   */
  private open(): void {
    this.scrollTop = this.windowService.getScrollTop();

    if (this.state.onOpen) {
      this.state.onOpen();
    }

    this.toggleBodyInteractivity(false);

    this.modal2InteractionService.emit(Modal2EventEnum.open);
  }

  /**
   * Close modal
   */
  private close(): void {
    if (this.state.onClose) {
      this.state.onClose();
    }

    this.toggleBodyInteractivity(true);

    this.windowService.getNative().scrollTo(0, this.scrollTop);

    this.modal2InteractionService.emit(Modal2EventEnum.close);
  }

  /**
   * Toggle interactivity of the document body
   */
  private toggleBodyInteractivity = (isInteractive: boolean) => {
    if (this.state.isFullScreen) {
      const action = isInteractive ? 'remove' : 'add';
      this.htmlElement.header?.classList[action](Modal2CssClassEnum.headerHidden);
      this.htmlElement.main?.classList[action](Modal2CssClassEnum.mainHidden);
      this.htmlElement.footer?.classList[action](Modal2CssClassEnum.footerHidden);
    } else {
      this.browserDocumentService.setScrollable(isInteractive);
    }
  };

  /**
   * When content reference have been created
   */
  private onContentRef = (contentElement: HTMLElement): void => {
    if (!contentElement) {
      return;
    }
    this.contentElement = contentElement;
  };

  /**
   * Handle click on modal container
   */
  private onClick = (e: Event) => {
    if (this.contentElement && this.contentElement.contains(e.target as HTMLElement)) {
      return;
    }

    this.setIsOpened(false);
  };

  /**
   * Handle click on trigger element
   */
  private onClickOpen = (e: Event) => {
    e.stopPropagation();
    this.setIsOpened(true);
  };

  /**
   * Handle click on close element
   */
  private onClickClose = () => {
    this.setIsOpened(false);
  };

  /**
   * Fired document keyup event
   */
  private onEsc = () => this.setIsOpened(false);
}
