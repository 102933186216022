import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { KeyboardStore } from 'common/module/keyboard/store';

const service = new KeyboardStore(EventEmitterFactory(), WindowService());

service.initialize();

export const KeyboardService = () => service;
