import { IconSpriteTimes } from '@propertyfinder/icons';
import { FunctionalComponent, h } from 'preact';

import { Modal3TemplatePropsInterface } from 'common/module/modal3/template-props.interface';

export const Modal3Template: FunctionalComponent<Modal3TemplatePropsInterface> = (props) => (
  <div className='modal3'>
    <div className={`modal3__content ${props.contentCssClass || ''}`}>
      {props.children}
      <div className='modal3__close' onClick={props.onClickClose}>
        <IconSpriteTimes />
      </div>
    </div>
  </div>
);
