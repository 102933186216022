import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { FilterValueBaseInterface } from 'common/module/filter/value-base.interface';
import { FilterWidgetTypeEnum } from 'desktop/module/filter-widget/type.enum';

export function filterWidgetGetActiveHiddenFiltersCount(
  filterParams: FilterParametersBaseInterface,
  hiddenFiltersTypes: FilterWidgetTypeEnum[],
  defaultValues: FilterValueBaseInterface
): number {
  const isFilterChanged = (type: string) =>
    JSON.stringify(filterParams[type as keyof FilterParametersBaseInterface]?.value) !==
    JSON.stringify(defaultValues[type as keyof FilterParametersBaseInterface]);

  return hiddenFiltersTypes.reduce((result, filterTypes) => {
    filterTypes.split('|').forEach((filterType) => {
      if (filterType.includes('&')) {
        filterType.split('&').some((subFilterType) => {
          if (isFilterChanged(subFilterType)) {
            result++;
            return true;
          }
        });
      } else if (isFilterChanged(filterType)) {
        result++;
      }
    });
    return result;
  }, 0);
}
