import { FunctionalComponent, h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { numberFormat } from 'common/helper/number/format';
import { ButtonComponent } from 'common/module/button/component';
import { ButtonComponentTypeEnum } from 'common/module/button/component-type.enum';
import { Modal2WrapperComponent } from 'common/module/modal2/wrapper-component';
import { FilterWidgetMoreFiltersComponent } from 'desktop/module/filter-widget/more-filters-component';

import { SearchBarMoreFiltersTemplatePropsInterface } from './template-props.interface';

export const SearchBarMoreFiltersTemplate: FunctionalComponent<SearchBarMoreFiltersTemplatePropsInterface> = (
  props
) => {
  const onSearchSubmit = () => {
    props.onSubmit(props.filterParams);
  };

  return (
    <Modal2WrapperComponent
      onClose={props.onClose}
      onOverlayClick={onSearchSubmit}
      bodyCssClass='more-filters__container'
      contentWrapperCssClass='more-filters__modal-container'
      contentCssClass='more-filters__modal-content'
    >
      <div className='more-filters__header more-filters__separator more-filters__separator--top'>
        <h1 className='more-filters__header-title'>{i18nTranslate('More Filters')}</h1>
      </div>
      <div className='more-filters__content'>
        {props.visibleFilterTypes.map((filterType) => (
          <div className='more-filters__section'>
            <FilterWidgetMoreFiltersComponent
              key={filterType}
              filterType={filterType}
              onChangeFilters={props.onChangeFilters}
              filterParams={props.filterParams}
            />
          </div>
        ))}
      </div>
      <div className='more-filters__footer more-filters__separator more-filters__separator--bottom'>
        {props.showResetButton && (
          <button className='more-filters__reset-button' type='button' onClick={props.onReset}>
            {i18nTranslate('Reset')}
          </button>
        )}
        <ButtonComponent
          cssClass='more-filters__find-button'
          componentType={ButtonComponentTypeEnum.type2}
          onClick={() => props.onSubmit(props.filterParams)}
          isDisabled={props.loadingCount}
          isInverted={true}
          isLoading={props.loadingCount}
        >
          {!props.loadingCount &&
            `${i18nTranslate('Show {count} results', props.resultCount).replace(
              '{count}',
              `${numberFormat(props.resultCount)}`
            )}`}
        </ButtonComponent>
      </div>
    </Modal2WrapperComponent>
  );
};
