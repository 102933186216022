import { h } from 'preact';

import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { SearchBarComponent } from 'desktop/module/search-bar/component';

import { PropertySerpSearchBarComponentFactoryPropsInterface } from './search-bar-component-factory-props.interface';

export const PropertySerpSearchBarComponentFactory =
  ({ filterSearchUrlAdapterFactory, getSearchUri }: PropertySerpSearchBarComponentFactoryPropsInterface) =>
  () => {
    const onSubmit = (filterParams: FilterParametersInterface) => {
      filterSearchUrlAdapterFactory().redirect(getSearchUri(), {
        ...filterParams,
        [FilterParamsEnum.pageNumber]: {
          value: getFilterDefaultParams()[FilterParamsEnum.pageNumber],
        },
      });
    };

    return <SearchBarComponent onSubmit={onSubmit} />;
  };
