import { useConstructor } from '@propertyfinder/helpers';
import { h } from 'preact';
import { useState } from 'preact/hooks';

import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { useFilterStoreParams } from 'common/module/filter/store/params';
import { useResultCount } from 'common/module/filter/store/result-count';
import { FilterWidgetTypeEnum } from 'desktop/module/filter-widget/type.enum';

import { SearchBarMoreFiltersComponentFactoryPropsInterface } from './component-factory-props.interface';
import { SearchBarMoreFiltersComponentPropsInterface } from './component-props.interface';
import { SearchBarMoreFiltersTemplate } from './template';

function areFiltersDirty(
  filterParams: FilterParametersBaseInterface,
  visibleFilterTypes: FilterWidgetTypeEnum[]
): boolean {
  const defaultValues = getFilterDefaultParams();
  return !!visibleFilterTypes.find((filterType) =>
    filterType
      .split('|')
      .find(
        (t) =>
          filterParams[t as keyof FilterParametersBaseInterface] &&
          JSON.stringify(filterParams[t as keyof FilterParametersBaseInterface].value) !==
            JSON.stringify(defaultValues[t as keyof FilterParametersBaseInterface])
      )
  );
}

function clearFilters(visibleFilterTypes: FilterWidgetTypeEnum[]): FilterParametersBaseInterface {
  const defaultValues = getFilterDefaultParams();

  return visibleFilterTypes.reduce((result: FilterParametersBaseInterface, filterType) => {
    filterType.split('|').forEach((t) => {
      result[t as keyof FilterParametersBaseInterface] = {
        value: defaultValues[t as keyof FilterParametersBaseInterface] as any,
        choices: [],
      };
    });
    return result as FilterParametersBaseInterface;
  }, {});
}

export const SearchBarMoreFiltersComponentFactory =
  ({ filterStore, filterSearchStore }: SearchBarMoreFiltersComponentFactoryPropsInterface) =>
  (props: SearchBarMoreFiltersComponentPropsInterface) => {
    const [onChangeFilters] = useState(() => filterStore.update.bind(filterStore));
    useConstructor(() => filterStore.syncWithoutValidation(props.initialFilterParams));
    const filterParams = useFilterStoreParams(filterStore);
    const resultCount = useResultCount(filterSearchStore, filterStore);

    return (
      <SearchBarMoreFiltersTemplate
        loadingCount={resultCount === -1}
        resultCount={resultCount}
        showResetButton={areFiltersDirty(filterParams, props.includedFilterTypes)}
        onChangeFilters={onChangeFilters}
        filterParams={filterParams}
        visibleFilterTypes={props.includedFilterTypes}
        onSubmit={props.onSubmit}
        onClose={props.onClose}
        onReset={() => onChangeFilters(clearFilters(props.includedFilterTypes))}
      />
    );
  };
