import { FunctionalComponent, h } from 'preact';

import { chooseAOrDefault } from 'common/helper/choose/a-or-default';
import { Modal2TemplatePropsInterface } from 'common/module/modal2/template-props.interface';

import { preactLifecycleComponent } from '../preact/lifecycle-component';

const Markup: FunctionalComponent<Modal2TemplatePropsInterface> = (props) => (
  <div
    className={`
    modal2
    ${props.isFullScreen && 'modal2--full-screen'}
    ${props.cssClass || ''}
  `}
    onClick={props.onClick}
  >
    {props.isHeaderVisible && (
      <div
        className={`
      modal2__header
      ${props.headerCssClass || ''}
    `}
      >
        {props.header}
        <div onClick={props.onClickClose}>
          {chooseAOrDefault(
            props.closeButton,
            <span className='svg modal2__close'>
              <svg viewBox='0 0 30 20' className='modal2__close-icon'>
                <use xlinkHref='#svg-close' />
              </svg>
            </span>
          )}
        </div>
      </div>
    )}
    {chooseAOrDefault(
      props.content,
      <div
        className={`
          modal2__content
          ${props.contentCssClass || ''}
          ${props.isFullScreen && 'modal2__content--full-screen'}
        `}
        ref={props.onContentRef}
      >
        {props.children}
      </div>
    )}
  </div>
);

export const Modal2Template = preactLifecycleComponent(Markup);
