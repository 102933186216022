import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { PropertySearchModel } from 'common/data/property-search/model';
import { PropertySearchStore } from 'common/data/property-search/store';
import { CookieAuthenticatorApiService } from 'common/module/cookie-authenticator/api.service';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';

export function PropertySearchStoreFactory(): PropertySearchStore {
  return new PropertySearchStore(
    EventEmitterFactory(),
    CookieAuthenticatorApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<PropertySearchModel>('search')
  );
}
