import { useConstructor } from '@propertyfinder/helpers';
import { useEffect } from 'preact/hooks';

import { DataStoreEvent } from 'common/module/data/store.event';

import { UseFilterStoreSyncFromServicePropsInterface } from './sync-from-service-props.interface';

export const useFilterStoreSyncFromService = ({
  filterService,
  filterStore,
}: UseFilterStoreSyncFromServicePropsInterface): void => {
  const sync = (): void => filterStore.syncWithoutValidation(filterService.getState());

  useConstructor(sync);

  useEffect(() => {
    // Sync any time service is updated
    filterService.getEventEmitter().addListener(DataStoreEvent.updateState, sync);
    return (): void => {
      filterService.getEventEmitter().removeListener(DataStoreEvent.updateState, sync);
    };
  }, []);
};
