import { StatsContexterService } from 'pf-frontend-common/src/service/stats-contexter/service';

import { preactMarkup } from 'common/helper/preact/markup';
import { FilterService } from 'common/service/filter/service';
import { StatsService } from 'common/service/stats/service';
import { ErrorPageView } from 'desktop/module/error-page/view';
import { PropertySerpSearchBarComponent } from 'desktop/module/property/serp/search-bar-component';

export function ErrorPageViewFactory(): ErrorPageView {
  return new ErrorPageView(
    StatsContexterService(),
    StatsService(),
    preactMarkup,
    { PropertySerpSearchBarComponent },
    FilterService()
  );
}
