import { domQuerySelector } from '@propertyfinder/pf-frontend-common/dist/helper/dom/query-selector';
import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { BrowserDocumentService } from 'pf-frontend-common/src/service/browser-document/service';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { Modal2ViewStore } from 'common/module/modal2/view-store';
import { KeyboardService } from 'common/service/keyboard/service';
import { Modal2InteractionService } from 'common/service/modal2-interaction/service';

export const Modal2ViewStoreFactory = () =>
  new Modal2ViewStore(
    EventEmitterFactory(),
    WindowService(),
    BrowserDocumentService(),
    KeyboardService(),
    BrowserDocumentService(),
    domQuerySelector,
    Modal2InteractionService()
  );
