import { BrowserDocumentService } from '@propertyfinder/pf-frontend-common/src/service/browser-document/service';
import { FunctionalComponent, h } from 'preact';
import { useEffect } from 'preact/hooks';

import { Modal2Component } from 'common/module/modal2/component';
import { Modal3Template } from 'common/module/modal3/template';

import { Modal2WrapperComponentPropsInterface } from './wrapper-component-props.interface';

export const Modal2WrapperComponent: FunctionalComponent<Modal2WrapperComponentPropsInterface> = ({
  onClose,
  bodyCssClass,
  contentWrapperCssClass,
  contentCssClass,
  children,
  onOverlayClick,
  ...modalProps
}) => {
  useEffect(() => {
    const documentService = BrowserDocumentService();
    documentService.setScrollable(false);
    return () => {
      documentService.setScrollable(true);
    };
  }, []);

  return (
    <Modal2Component {...modalProps} isOpened onClose={onClose}>
      <div className='modal modal-open'>
        <div className={`modal_body ${bodyCssClass || ''}`}>
          <div className={`modal_content ${contentWrapperCssClass || ''}`} onClick={(e) => e.stopPropagation()}>
            <Modal3Template onClickClose={onClose} contentCssClass={contentCssClass}>
              {children}
            </Modal3Template>
          </div>
        </div>
        <div className='modal_overlay modal_overlay-open' onClick={onOverlayClick || onClose} />
      </div>
    </Modal2Component>
  );
};
