import { Component, toChildArray, VNode } from 'preact';
import { createPortal } from 'preact/compat';

import { PreactPortalComponentPropsInterface } from 'common/module/preact/portal/component-props.interface';

/** Redirect rendering of descendants into the given CSS selector or dom element.
 *  @example
 *    <Portal into="body">
 *      <div>I am rendered into document.body</div>
 *    </Portal>
 */
export class PreactPortalComponent extends Component<PreactPortalComponentPropsInterface> {
  /**
   * @inheritdoc
   */
  public render(): preact.JSX.Element {
    return createPortal(toChildArray(this.props.children)[0] as VNode<{}>, this.findNode(this.props.into));
  }

  /**
   * Returns html node if query selector is passed
   */
  private findNode(node: Element | string): Element {
    return typeof node === 'string' ? document.querySelector(node) : node;
  }
}
